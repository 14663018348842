import React, {useMemo} from 'react';
import classNames from 'classnames';
import {Field} from 'redux-form';

import Carrier from 'core/entities/Carrier/types';

import CheckBoxInput from 'components/ui/Form/CheckBoxInput';
import TooltipBootstrap from 'components/ui/TooltipBootstrap';

import useSelectCompaniesState from 'pages/Trucks/components/modals/SelectCompanies/hooks/useSelectCompaniesState';
import useSyncTableRows from 'pages/Trucks/components/modals/SelectCompanies/hooks/useSyncTableRows';
import {SelectCompaniesModalFormValues} from 'pages/Trucks/types/formTypes';

import {getTypeFieldNameFactory} from 'utils/typeScript';

import {highlightSubstring} from './utils';

import styles from './styles.module.scss';

const getName = getTypeFieldNameFactory<SelectCompaniesModalFormValues>();

type OwnProps = {
    company: Carrier;
    index: number;
};

const CompanyNameInfo: React.FC<OwnProps> = (props) => {
    const {company, index} = props;

    const {isCompanyNotAvailable, isCompanyLoadPlannedOrInProgress} = useSyncTableRows({company, index});
    const {searchParams} = useSelectCompaniesState();

    const companyIsNotAvailableTooltip = (
        <>
            The company is not available for activation. <br />
            To enable a company, add it to the owner page
        </>
    );

    const loadIsPlannedOrInProgressTooltip = (
        <>
            You cannot turn off the company because <br /> the load is planned or in progress
        </>
    );

    const checkBoxLabel = highlightSubstring(company.company_name, searchParams?.companyName);

    const memoizedField = useMemo(
        () => (
            <Field
                valueAs={{label: company.company_name, value: company.id}}
                name={`${getName('selectedCompanies')}.${index}`}
                disabled={isCompanyNotAvailable}
                component={CheckBoxInput}
                position="right"
            />
        ),
        [],
    );

    return (
        <div className={styles.wrap}>
            {memoizedField}
            {checkBoxLabel}

            {isCompanyNotAvailable ? (
                <div className={styles.badge}>
                    <TooltipBootstrap tooltipContent={companyIsNotAvailableTooltip}>
                        <i className="fa fa-exclamation-triangle" />
                    </TooltipBootstrap>
                </div>
            ) : null}

            {isCompanyLoadPlannedOrInProgress ? (
                <div className={classNames(styles.badge, styles.badge__yellow)}>
                    <TooltipBootstrap tooltipContent={loadIsPlannedOrInProgressTooltip}>
                        <i className="fa fa-exclamation-triangle" />
                    </TooltipBootstrap>
                </div>
            ) : null}
        </div>
    );
};

export default CompanyNameInfo;
