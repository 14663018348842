import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import classNames from 'classnames';
import compact from 'lodash/compact';
import concat from 'lodash/concat';

import Truck from 'core/entities/Truck/types';
import {DispatcherNoteApi, Note} from 'core/entities/Truck/types/Note';

import {deleteGeneralNote} from 'services/restapi/generalNotes';

import ButtonGroupSwitch from 'components/ui/ButtonGroupSwitch';
import {openModal} from 'components/ui/ModalProvider/actions';
import {commonModalNames} from 'components/ui/modals/modalMap';

import useTrucksPermissions from 'pages/Trucks/hooks/useTrucksPermissions';

import Form from './components/NotesForm';
import NotesList from './components/NotesList';

import {fetchGeneralTruckNotes, postDispatcherNote, postGeneralNote} from './requests';
import {FormTab, Tab} from './types';
import {convertDispatcherNote, getDispatcherDriverTrucksNotes} from './utils';

import styles from './trucksNotes.module.scss';

const tabs: {[key in Tab]: Tab} = {
    all: 'all',
    dispatcher: 'dispatcher',
    firstDriver: 'firstDriver',
    general: 'general',
};

const formTabs: {[key in FormTab]: FormTab} = {
    dispatcher: 'dispatcher',
    general: 'general',
};

type OwnProps = {
    receiveDispatcherNote: (params: {truck: Truck; note: DispatcherNoteApi}) => void;

    isDisableDispatcherLinks?: boolean;
    isDisableDriverLinks?: boolean;
    isNeedGeneralNotes?: boolean;
    onlyViewNotes?: boolean;
    withTitle?: boolean;
    truck: Truck;
};

const TruckNotes: React.FC<OwnProps> = (props) => {
    const {
        isDisableDispatcherLinks = false,
        isDisableDriverLinks = false,
        isNeedGeneralNotes = true,
        receiveDispatcherNote,
        onlyViewNotes = false,
        withTitle = false,
        truck,
    } = props;

    const {dispatcherNote, firstDriverNote} = getDispatcherDriverTrucksNotes(truck);
    const [generalTruckNotes, setGeneralTruckNotes] = useState<Note[]>([]);
    const [updateDispatcherNote, setDispatcherTruckNotes] = useState<Note[]>(dispatcherNote);
    const {hasUpdatePermission, hasNotesViewPermission, hasNotesUpdatePermission} = useTrucksPermissions();

    useEffect(() => {
        if (isNeedGeneralNotes && hasNotesViewPermission) {
            fetchGeneralTruckNotes(truck).then((response) => setGeneralTruckNotes(response));
        }
    }, []);

    const [activeTab, setActiveTab] = useState<Tab>(tabs.all);
    const selectAllTab = useCallback(() => setActiveTab(tabs.all), []);
    const selectGeneralTab = useCallback(() => setActiveTab(tabs.general), []);
    const selectDispatcherTab = useCallback(() => setActiveTab(tabs.dispatcher), []);
    const selectFirstDriverTab = useCallback(() => setActiveTab(tabs.firstDriver), []);

    const dispatch = useDispatch();

    const tabNotes = useMemo(
        function () {
            if (activeTab === tabs.all) {
                const mergedNotes = concat(updateDispatcherNote, firstDriverNote, generalTruckNotes);
                return compact(mergedNotes);
            }
            if (activeTab === tabs.general) {
                return generalTruckNotes;
            }
            if (activeTab === tabs.firstDriver) {
                return firstDriverNote;
            }

            return updateDispatcherNote;
        },
        [generalTruckNotes, firstDriverNote, updateDispatcherNote, activeTab],
    );

    const [activeFormTab, setActiveFormTab] = useState<FormTab>(formTabs.dispatcher);
    const selectFormDispatcherTab = useCallback(() => setActiveFormTab(formTabs.dispatcher), [truck]);
    const selectFormGeneralTab = useCallback(() => setActiveFormTab(formTabs.general), [truck]);

    useEffect(() => {
        if (activeTab === tabs.all) {
            setActiveFormTab(formTabs.dispatcher);
        }
        if (activeTab === tabs.general) {
            setActiveFormTab(formTabs.general);
        }
        if (activeTab === tabs.dispatcher) {
            setActiveFormTab(formTabs.dispatcher);
        }
    }, [activeTab]);

    const tabForm = useMemo(
        function () {
            if (activeTab === tabs.general) {
                return [
                    {
                        name: formTabs.general,
                        label: 'New General Note',
                        isDisabled: truck.is_deleted,
                        onSelect: selectFormGeneralTab,
                    },
                ];
            }

            if (activeTab === tabs.dispatcher) {
                return [
                    {
                        name: formTabs.dispatcher,
                        label: "New dispatcher's Note",
                        isDisabled: truck.is_deleted,
                        onSelect: selectFormDispatcherTab,
                    },
                ];
            }
            const newNoteTabs = [
                {
                    label: "New dispatcher's Note",
                    name: formTabs.dispatcher,
                    isDisabled: truck.is_deleted,
                    onSelect: selectFormDispatcherTab,
                },
            ];

            if (hasNotesUpdatePermission) {
                newNoteTabs.push({
                    label: 'New General Note',
                    name: formTabs.general,
                    isDisabled: truck.is_deleted,
                    onSelect: selectFormGeneralTab,
                });
            }

            return newNoteTabs;
        },
        [activeTab, activeFormTab, truck, hasNotesUpdatePermission],
    );

    const handleSubmit = useCallback(
        (note) => {
            if (activeFormTab === formTabs.dispatcher) {
                return postDispatcherNote(note.note, truck.id).then((res) => {
                    setDispatcherTruckNotes([convertDispatcherNote(res)]);
                    receiveDispatcherNote({truck, note: res});
                });
            }

            return postGeneralNote(note.note, truck.id).then((res) =>
                setGeneralTruckNotes(concat(res, generalTruckNotes)),
            );
        },
        [activeTab, activeFormTab, generalTruckNotes, updateDispatcherNote],
    );

    const submitDelete = (id: number): void => {
        if (deleteGeneralNote) {
            deleteGeneralNote(id).finally(() => {
                fetchGeneralTruckNotes(truck).then((response) => setGeneralTruckNotes(response));
            });
        }
    };

    const handleNoteDelete = (id): void => {
        const errorMessage = 'Are you sure you want to delete the note?';

        dispatch(
            openModal({
                modalName: commonModalNames.informationModal,
                data: {
                    title: `Delete Note`,
                    errorMessage,
                    leftButtonTitle: 'cancel',
                    rightButtonTitle: 'delete',
                    bodyType: 'ErrorForm',
                    buttonType: 'danger',
                },
                handlers: {
                    leftButtonHandler: () => {},
                    rightButtonHandler: () => submitDelete(id),
                },
            }),
        );
    };

    const DeleteNote = (params) => {
        const {note} = params;

        if (hasUpdatePermission) {
            return (
                <a
                    onClick={() => {
                        handleNoteDelete(note.id);
                    }}
                >
                    <i className="fa fa-trash" aria-hidden="true" />
                </a>
            );
        }

        return <></>;
    };

    const getTabsController = () => {
        const tabsController = [
            {
                label: 'all',
                name: tabs.all,
                onSelect: selectAllTab,
            },
            {
                label: "dispatcher's",
                name: tabs.dispatcher,
                onSelect: selectDispatcherTab,
            },
            {
                label: "first driver's",
                name: tabs.firstDriver,
                onSelect: selectFirstDriverTab,
            },
        ];

        if (isNeedGeneralNotes && hasNotesViewPermission) {
            tabsController.push({
                label: 'general',
                name: tabs.general,
                onSelect: selectGeneralTab,
            });
        }

        return tabsController;
    };

    return (
        <div className={styles.main}>
            <div className={classNames(styles.list, onlyViewNotes && styles.list__view)}>
                <div className={styles.group}>
                    {withTitle ? (
                        <div className={styles.title}>
                            <strong>Truck&rsquo;s note</strong>
                        </div>
                    ) : null}

                    <ButtonGroupSwitch activeKey={activeTab} buttons={getTabsController()} />
                </div>

                <NotesList
                    isDisableDispatcherLinks={isDisableDispatcherLinks}
                    isDisableDriverLinks={isDisableDriverLinks}
                    tabsName={activeTab}
                    notes={tabNotes}
                >
                    <DeleteNote />
                </NotesList>
            </div>

            {activeTab !== tabs.firstDriver && !onlyViewNotes && (
                <div className={styles.form}>
                    <div className={styles.form__tabs}>
                        <ButtonGroupSwitch activeKey={activeFormTab} buttons={tabForm} />
                    </div>

                    <div className={styles.form__content}>
                        <Form
                            truck={truck}
                            onSubmit={handleSubmit}
                            formNoteType={activeFormTab}
                            permissionAddNote={hasUpdatePermission}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default TruckNotes;
