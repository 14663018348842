import React, {FC} from 'react';

import Button, {ButtonType} from './components/Button';

type OwnProps = {
    buttons: ButtonType[];
    activeKey: string;
};

const ButtonGroupSwitch: FC<OwnProps> = (props) => {
    const {buttons, activeKey} = props;

    return (
        <div className="btn-group__filter">
            <div className="btn-group" role="group">
                {buttons.map((buttonProps) => {
                    const {name} = buttonProps;

                    return <Button {...buttonProps} key={name} activeKey={activeKey} />;
                })}
            </div>
        </div>
    );
};

export default React.memo(ButtonGroupSwitch);
