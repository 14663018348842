import {SidebarItems} from 'core/entities/AppSettings/types';

import useAuction from 'hooks/useAuction';
import useDomainSettings from 'hooks/useDomainSettings';

type UseSidebar = {
    isSidebarExpanded: boolean;
    menuItems: SidebarItems;
};

const useSidebar = (): UseSidebar => {
    const {sidebarMenu, isSidebarExpanded} = useDomainSettings();
    // TODO: temporary, logic need to be moved to backend
    const isAuctionEnabled = useAuction();

    return {
        menuItems: sidebarMenu.filter((item) => (item.name === 'auction' ? isAuctionEnabled : true)),
        isSidebarExpanded,
    };
};

export default useSidebar;
