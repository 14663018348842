import isFunction from 'lodash/isFunction';
import {change} from 'redux-form';

import {sortActions} from 'store/actionCreators';
import * as appActions from 'store/actions';

import Carrier from 'core/entities/Carrier/types';
import {fetchCompanies} from 'core/gateways/CarrierApiGateway/requests';

import fields from 'pages/Owners/constants/fields';
import * as actionTypes from 'pages/Owners/redux/actionTypes/selectCompanies';
import {transformDataToRequestBody} from 'pages/Owners/redux/mappers/selectCompanies';
import {SelectCompaniesModalFormValues} from 'pages/Owners/types/formTypes';

export const selectCompaniesActionCreators = {
    getCompaniesByFilters: (payload: {filters: Partial<SelectCompaniesModalFormValues>}) =>
        ({type: actionTypes.COMPANIES_BY_FILTERS_RECEIVED, payload} as const),
    receiveCompaniesSearchParams: (payload: {filters: Partial<SelectCompaniesModalFormValues>}) =>
        ({type: actionTypes.COMPANIES_SEARCH_PARAMS_RECEIVED, payload} as const),
    receiveInitialCompanies: (payload: {companies: Carrier[]}) =>
        ({type: actionTypes.INITIAL_COMPANIES_RECEIVED, payload} as const),
    receiveCompaniesBySorting: (payload: {companies: Carrier[]}) =>
        ({type: actionTypes.COMPANIES_BY_SORTING_RECEIVED, payload} as const),
    clearSelectCompaniesState: () => ({type: actionTypes.COMPANIES_STATE_CLEARED} as const),
};

export const getCompaniesList = (params: {requestPayload}) => async (dispatch) => {
    const {requestPayload} = params;

    try {
        const {data} = await fetchCompanies({requestPayload});

        return {items: data || []};
    } catch (error) {
        console.log(error);
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const getInitialSelectCompaniesList = () => async (dispatch, getState) => {
    const state = getState();

    const requestPayload = transformDataToRequestBody({state});

    dispatch(appActions.showLoader());

    const searchResult = await dispatch(getCompaniesList({requestPayload}));

    if (!searchResult) {
        return null;
    }

    dispatch(selectCompaniesActionCreators.receiveInitialCompanies({companies: searchResult.items}));
};

export const getSimilarCompaniesDropdown = (params: {formValues: Partial<SelectCompaniesModalFormValues>}) => async (
    dispatch,
    getState,
) => {
    const {formValues} = params;

    const state = getState();

    const requestPayload = transformDataToRequestBody({state, formValues});

    const searchResult = await dispatch(getCompaniesList({requestPayload}));

    return searchResult.items.map((item) => ({label: item.company_name, value: item.company_name}));
};

export const getCompaniesByFilters = (params: {
    filters: Partial<SelectCompaniesModalFormValues>;
    onPostSuccessFunc?: () => void;
}) => (dispatch) => {
    const {filters, onPostSuccessFunc} = params;

    if (isFunction(onPostSuccessFunc)) {
        onPostSuccessFunc();
    }

    dispatch(selectCompaniesActionCreators.getCompaniesByFilters({filters}));
    dispatch(selectCompaniesActionCreators.receiveCompaniesSearchParams({filters}));
};

export const getCompaniesBySorting = () => async (dispatch, getState) => {
    const state = getState();

    const requestPayload = transformDataToRequestBody({state});

    dispatch(appActions.showLoader());

    const searchResult = await dispatch(getCompaniesList({requestPayload}));

    if (!searchResult) {
        return null;
    }

    dispatch(selectCompaniesActionCreators.receiveCompaniesBySorting({companies: searchResult.items}));
};

export const saveSelectedCompanies = (params: {
    formValues: Partial<SelectCompaniesModalFormValues>;
    formName: string;
}) => (dispatch) => {
    const {formValues, formName} = params;

    dispatch(change(formName, fields.general.workWithCompanies, formValues.selectedCompanies));
};

export const clearSelectCompaniesModalState = () => (dispatch) => {
    dispatch(selectCompaniesActionCreators.clearSelectCompaniesState());
    dispatch(sortActions.clearSelectCompaniesModalSort());
};
