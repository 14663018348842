import React, {useEffect} from 'react';
import {Modal} from 'react-bootstrap';

import {SELECT_COMPANIES_FORM_NAME} from 'pages/Trucks/constants';
import fields from 'pages/Trucks/constants/fields';
import useModalActions from 'pages/Trucks/hooks/useModalActions';
import {SelectCompaniesModalFormValues} from 'pages/Trucks/types/formTypes';

import {getTypeFieldNameFactory} from 'utils/typeScript';

import useChangeReduxFormValue from 'hooks/reduxForm/useChangeReduxFormValue';
import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import Form from './components/Form';
import useSelectCompaniesActions from './hooks/useSelectCompaniesActions';

const getName = getTypeFieldNameFactory<SelectCompaniesModalFormValues>();

type OwnProps = {
    data: {formName: string};
};

const SelectCompanies: React.FC<OwnProps> = (props) => {
    const {
        data: {formName},
    } = props;

    const workWithCompaniesFormValue = useGetReduxFormValue(formName, fields.general.workWithCompanies);
    const changeFormValue = useChangeReduxFormValue(SELECT_COMPANIES_FORM_NAME);
    const selectCompaniesActions = useSelectCompaniesActions();
    const {closeModal} = useModalActions();

    useEffect(() => {
        selectCompaniesActions.getInitialCompaniesList();

        changeFormValue(getName('selectedCompanies'), workWithCompaniesFormValue);

        return () => {
            changeFormValue(getName('isAllCompaniesSelected'), null);
            changeFormValue(getName('companyName'), null);

            selectCompaniesActions.clearSelectCompaniesModalState();
        };
    }, []);

    const handleSubmit = (formValues: Partial<SelectCompaniesModalFormValues>) => {
        selectCompaniesActions.getCompaniesByFilters({filters: formValues});
    };

    return (
        <Modal show={true} onHide={closeModal} dialogClassName="standard-form-modal modal-lg" backdrop="static">
            <Modal.Header className="modal-header__top">
                <Modal.Title className="font-weight-bold">Select Companies</Modal.Title>

                <button onClick={closeModal} type="button" className="close">
                    <i className="fa fa-times" aria-hidden="true" />
                </button>
            </Modal.Header>

            <Modal.Body>
                <Form
                    initialValues={{[getName('selectedCompanies')]: workWithCompaniesFormValue}}
                    form={SELECT_COMPANIES_FORM_NAME}
                    externalFormName={formName}
                    destroyOnUnmount={false}
                    onSubmit={handleSubmit}
                />
            </Modal.Body>
        </Modal>
    );
};

export default SelectCompanies;
