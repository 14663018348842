import {CancelToken} from 'axios';
import head from 'lodash/head';

import PlannedTravelOrder from 'core/entities/TravelOrder/types/PlannedTravelOrder';
import Truck, {TruckOnMap} from 'core/entities/Truck/types';

import restapi, {fetchFiles} from 'services/restapi';
import {getGeneralNotes, postGeneralNote} from 'services/restapi/generalNotes';

import {ENTITY_NAME_TRUCKS} from 'utils/data/entityNames';

import {MainContactOptionsResponse, TruckEquipmentOptionsRespone} from '../types';

const baseRoute = `/${ENTITY_NAME_TRUCKS}`;
const TRUCK_MAP_URL = `${baseRoute}/map`;

/**
 *
 * @param {number} truckNumber
 * @return {Promise}
 */
export function getTruckByNumber(truckNumber) {
    return restapi(`${baseRoute}/search/${truckNumber}`);
}

/**
 *
 * @param {Object} [params]
 * @param {CancelToken} cancelToken axios cancel token
 * @return {Promise}
 */
export function getTrucks(params, cancelToken: CancelToken | null = null) {
    return restapi.post(`${baseRoute}/search`, {...params, cancelToken});
}

/**
 *
 * @param {Object} params
 * @return {Promise}
 */
export function searchTrucks(params) {
    return restapi.post(`${baseRoute}/search`, params);
}

/**
 *
 * @param {String} vinCode
 * @return {Promise}
 */
export function searchTrucksByVinCode(vinCode) {
    return restapi.get(`${baseRoute}/search-by-vincode/${vinCode}`);
}

/**
 *
 * @param {Object} data
 * @return {Promise}
 */
export function postTruck(data) {
    return restapi.post(baseRoute, data);
}

/**
 *
 * @param {number} truckId
 * @param {Object} data
 * @return {Promise}
 */
export function putTruck(truckId, data) {
    // temporarily using a post method because of incorrect routing on the API side
    return restapi.post(`${baseRoute}/${truckId}`, data);
}

export function updateTruckStatus({truckID, requestBody}) {
    return restapi.put(`/v2/truck/${truckID}/update-status`, requestBody);
}

export function archiveTruck(truck: Truck): Promise<Truck> {
    return restapi.put(`${baseRoute}/${truck.number}/archive`).then(() => ({...truck, is_deleted: true}));
}

export function restoreTruck(truck: Truck): Promise<Truck> {
    return restapi.put(`${baseRoute}/${truck.number}/restore`).then(() => ({...truck, is_deleted: false}));
}
/**
 *
 * @param {number} [truckId]
 * @return {Promise}
 */
export function getTruckNotes(truckId) {
    const params = {
        entity_name: ENTITY_NAME_TRUCKS,
        entity_id: truckId,
    };
    return getGeneralNotes(params).then(({data: notes}) => notes);
}

/**
 *
 * @param {number} [truckId]
 * @return {Promise}
 */
export function fetchTruckTravelOrders(truck) {
    const params = {
        number: truck.number,
    };
    return searchTrucks(params)
        .then(({data: listTrucks}) => {
            const searchedTruck: any = head(listTrucks);
            return searchedTruck ? searchedTruck.travelOrders : undefined;
        })
        .catch((error) => {
            console.error('Error on fetch truck travel orders: ', error);
        });
}

/**
 *
 * @param {number} truckId
 * @param {string} text
 * @return {Promise}
 */
export function postTruckNote(truckId, text) {
    const params = {
        entity_name: ENTITY_NAME_TRUCKS,
        entity_id: truckId,
        text,
    };
    return postGeneralNote(params).then(({data}) => data);
}

/**
 *
 * @param {number} truckId
 * @return {Promise}
 */
export function getTruckFiles(truckId) {
    const params = {
        id: truckId,
        entity: ENTITY_NAME_TRUCKS,
    };
    return fetchFiles(params).then((res) => {
        if ('data' in res) {
            return res.data;
        }
    });
}

/**
 *
 * @param {number} truckId
 * @return {Promise}
 */

export function getCurrentTravelOrder(truckId) {
    return restapi(`/to/truck/${truckId}/actual`).then(({data}) => head(data));
}

export function updateDispatcherNote(truckId, data) {
    return restapi.put(`${baseRoute}/${truckId}/dispatcher-note`, data);
}

export const getListTrucksOnMap = (params: any): Promise<TruckOnMap[]> => {
    return restapi.post(TRUCK_MAP_URL, params).then(({data}) => data);
};

export const fetchPlannedTravelOrders = (truckID: number) => {
    return restapi.get<PlannedTravelOrder[]>(`/v2/truck/${truckID}/travel-order/planned`).then(({data}) => data);
};

export const fetchMainContactOptions = (data: {ownerId: number; truckId?: number}) => {
    return restapi.options<MainContactOptionsResponse>('/trucks', {data});
};

export const fetchEquipment = () => {
    return restapi.options<TruckEquipmentOptionsRespone>(`/v2/truck`);
};

export default {
    getTruckByNumber,
    getTrucks,
    searchTrucks,
    postTruck,
    putTruck,
    updateTruckStatus,
    getTruckNotes,
    postTruckNote,
    getTruckFiles,
    updateDispatcherNote,
    getCurrentTravelOrder,
    fetchPlannedTravelOrders,
};
