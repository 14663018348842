import Truck from 'core/entities/Truck/types';

import {CurrentTruck, TruckDispatcherNote} from 'pages/Trucks/types';

import GeneralNote from 'types/GeneralNote';

export const TRUCK_CLEAR_LIST_ITEMS = 'TRUCK_CLEAR_LIST_ITEMS';
export const TRUCK_RECEIVED = 'TRUCK_COMPONENT_TRUCK_RECEIVED';
export const TRUCK_ARCHIVED = 'TRUCK_COMPONENT_TRUCK_ARCHIVED';
export const TRUCK_NOTE_ADDED = 'TRUCK_COMPONENT_NOTE_ADDED';
export const TRUCK_NOTE_DELETED = 'TRUCK_COMPONENT_NOTE_DELETED';
export const TRUCK_DISPATCHER_NOTE_UPDATED = 'TRUCKS_COMPONENT_DISPATCHER_NOTE_UPDATED';
export const TRUCK_STATE_CLEAR = 'TRUCKS_COMPONENT_STATE_CLEAR';
export const RECEIVE_TRUCKS = 'TRUCKS_COMPONENT_RECEIVE_TRUCKS';
export const TRUCKS_SET_SEARCH_PARAMS = 'TRUCKS_SET_SEARCH_PARAMS';
export const TRUCKS_SET_CURRENT_PAGE = 'TRUCKS_SET_CURRENT_PAGE';
export const TRUCKS_SET_PAGINATION = 'TRUCKS_SET_PAGINATION';
export const TRUCK_TOGGLE_ROW = 'TRUCKS_COMPONENT_TRUCK_TOGGLE_ROW';
export const TRUCK_TOGGLE_ALL_ROWS = 'TRUCKS_COMPONENT_TRUCK_TOGGLE_ALL_ROWS';
export const TRUCK_STATUS_UPDATE = 'TRUCKS_LIST_TRUCK_STATUS_UPDATE';
export const TRUCKS_SET_SORTING_PARAMS = 'TRUCKS_SET_SORTING_PARAMS';
export const CLEAR_STATE = 'TRUCKS_CLEAR_STATE';
export const TOGGLE_DRAWER_EXPAND = 'TOGGLE_DRAWER_EXPAND';
export const TRUCK_UPDATE_SET_ACTIVE_TAB = 'TRUCK_UPDATE_SET_ACTIVE_TAB';
export const TRUCK_UPDATE_RESET_TAB = 'TRUCK_UPDATE_RESET_TAB';
export const TRUCK_COMPANIES_TAB_ITEMS_BY_PAGE_RECEIVED = 'TRUCK_COMPANIES_TAB_ITEMS_BY_PAGE_RECEIVED';
export const TRUCK_COMPANIES_TAB_ITEMS_BY_PER_PAGE_RECEIVED = 'TRUCK_COMPANIES_TAB_ITEMS_BY_PER_PAGE_RECEIVED';
export const TRUCK_COMPANIES_TAB_ITEMS_ORDER_BY_RECEIVED = 'TRUCK_COMPANIES_TAB_ITEMS_ORDER_BY_RECEIVED';

interface TruckReceivedAction {
    type: typeof TRUCK_RECEIVED;
    payload: {truckData: CurrentTruck};
}

interface TruckNoteAddedAction {
    type: typeof TRUCK_NOTE_ADDED;
    payload: GeneralNote;
}

interface TruckNoteDeletedAction {
    type: typeof TRUCK_NOTE_DELETED;
    payload: {id: number};
}

interface TruckDispatcherNoteUpdatedAction {
    type: typeof TRUCK_DISPATCHER_NOTE_UPDATED;
    payload: {truck: Truck; note: TruckDispatcherNote};
}

interface StateClearAction {
    type: typeof TRUCK_STATE_CLEAR;
}

export type CurrentTruckActionTypes =
    | TruckReceivedAction
    | TruckNoteAddedAction
    | TruckNoteDeletedAction
    | TruckDispatcherNoteUpdatedAction
    | StateClearAction;
