import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {useLocation} from 'react-router-dom';
import isNumber from 'lodash/isNumber';

import getDispatcherName from 'core/entities/Dispatcher/getDispatcherName';
import {getTotalDistance} from 'core/entities/Load/modules/loadDistance';
import {getStopsInfo, isStopPlanned} from 'core/entities/Load/modules/loadStop';
import {PERMISSIONS} from 'core/entities/Permissions';
import {getTravelOrderTruckState} from 'core/entities/TravelOrder/modules/common/getTravelOrderTruckState';
import {getTravelOrderEmptyMiles} from 'core/entities/TravelOrder/modules/travelOrderDistances';
import {getLoadFromTravelOrder} from 'core/entities/TravelOrder/modules/travelOrderLoad';
import PlannedTravelOrder from 'core/entities/TravelOrder/types/PlannedTravelOrder';
import {fetchPlannedTravelOrders} from 'core/gateways/TruckApiGateway/requests';

import RefLink from 'components/common/Load/RefNumberLink';
import TimeFrameView from 'components/common/Load/TimeFrameView';
import Button from 'components/ui/Buttons/Button';
import {LoadLink, TravelOrderLink} from 'components/ui/Links';
import Tooltip from 'components/ui/Tooltip';

import {transformDistance} from 'utils/distance';

import useCheckIfUserHasPermission from 'hooks/useCheckIfUserHasPermission';

import styles from './plannedTravelOrders.module.scss';

const PlannedTravelOrders = ({data: {truck}, onClose}) => {
    const location = useLocation();
    const [plannedTravelOrders, setPlannedTravelOrders] = useState<PlannedTravelOrder[] | null>(null);

    const permissionViewTravelOrder = useCheckIfUserHasPermission(PERMISSIONS.TRAVEL_ORDER_VIEW);

    useEffect(() => {
        async function getPlannedTravelOrders() {
            const plannedTO = await fetchPlannedTravelOrders(truck.id);
            setPlannedTravelOrders(plannedTO);
        }
        getPlannedTravelOrders();
    }, []);

    useEffect(() => {
        return onClose;
    }, [location]);

    if (!plannedTravelOrders) {
        return null;
    }

    return (
        <div className={`standard-form-modal modal-content ${styles.modal}`}>
            <Modal.Header>
                <div className="modal-header__top">
                    <Modal.Title>
                        Planned Loads of the <strong>Truck#{truck.number}</strong>
                    </Modal.Title>
                    <button onClick={onClose} type="button" className="close">
                        <i className="fa fa-times" aria-hidden="true" />
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="standard-table-style custom-scroll">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>LOAD AND TO#</th>
                                <th>TO STEP</th>
                                <th>FIRST PICK UP</th>
                                <th>LAST DELIVERY</th>
                                <th>MILES</th>
                            </tr>
                        </thead>
                        <tbody className="table-body-bordered">
                            {plannedTravelOrders.map((travelOrder, index) => {
                                const rowNumber = index + 1;
                                const load = getLoadFromTravelOrder(travelOrder);
                                const {text} = getTravelOrderTruckState(travelOrder) || {};

                                if (!load) {
                                    return null;
                                }

                                const emptyMiles = getTravelOrderEmptyMiles(travelOrder);
                                const dispatcher = load?.createdByDispatcher;
                                const showEmptyMiles = isNumber(emptyMiles);
                                const {
                                    firstPickupStopAddress,
                                    lastDeliveryStopAddress,
                                    firstPickupStop,
                                    lastDeliveryStop,
                                } = getStopsInfo(load);
                                const isPlannedFirstStop = isStopPlanned(firstPickupStop);
                                const isPlannedLastStop = isStopPlanned(lastDeliveryStop);
                                const loadLinkTooltip = (
                                    <span>
                                        <span>load#{load.number}</span>
                                        <br />
                                        <span>
                                            booked by: <strong>{getDispatcherName(dispatcher)}</strong>
                                        </span>
                                        <br />
                                        <span>
                                            <em>click to view the load</em>
                                        </span>
                                        <br />
                                    </span>
                                );
                                const isFromOtherServer = travelOrder.isForeign;

                                return (
                                    <tr key={travelOrder.number}>
                                        <td>{rowNumber}.</td>
                                        <td>
                                            {isFromOtherServer ? (
                                                'Partners load'
                                            ) : (
                                                <Tooltip tooltipContent={loadLinkTooltip}>
                                                    <strong>
                                                        <LoadLink
                                                            load={load}
                                                            content={`Load#${load.number}`}
                                                            className="second-link"
                                                        />
                                                    </strong>
                                                </Tooltip>
                                            )}
                                            <br />
                                            {isFromOtherServer ? (
                                                'Partners TO'
                                            ) : (
                                                <TravelOrderLink
                                                    travelOrder={travelOrder}
                                                    content={`T.O#${travelOrder.number}`}
                                                    className="second-link"
                                                    disabled={!permissionViewTravelOrder}
                                                    isBold={true}
                                                />
                                            )}
                                        </td>
                                        <td>
                                            {isFromOtherServer ? (
                                                'No data'
                                            ) : (
                                                <>
                                                    <b>{text}</b>
                                                    <br />
                                                    <RefLink load={load} />
                                                </>
                                            )}
                                        </td>
                                        <td>
                                            {firstPickupStopAddress} <br />
                                            <TimeFrameView
                                                loadStop={firstPickupStop}
                                                isHighlightDelay={isPlannedFirstStop}
                                                inLine={false}
                                            />
                                        </td>
                                        <td>
                                            {lastDeliveryStopAddress}
                                            <br />
                                            <TimeFrameView
                                                loadStop={lastDeliveryStop}
                                                isHighlightDelay={isPlannedLastStop}
                                                inLine={false}
                                            />
                                        </td>
                                        <td>
                                            loaded:
                                            <b> {transformDistance(getTotalDistance(load)).distanceWithShortUnit}</b>
                                            <br />
                                            {showEmptyMiles ? (
                                                <>
                                                    empty:
                                                    <b> {transformDistance(emptyMiles).distanceWithShortUnit} </b>
                                                </>
                                            ) : null}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="modal-body__bottom">
                    <div>
                        <Button onClick={onClose}>Close</Button>
                    </div>
                </div>
            </Modal.Body>
        </div>
    );
};

export default PlannedTravelOrders;
