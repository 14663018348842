import {PERMISSIONS} from 'core/entities/Permissions';

import useCheckIfUserHasPermission from 'hooks/useCheckIfUserHasPermission';
import useInitialLoadWithPermission from 'hooks/useInitialLoadWithPermission';

type UseTrucksPermissions = {
    hasAllianceCompanyNameFieldViewPermission: boolean;
    hasStatusAndLocationUpdatePermission: boolean;
    hasStatusAndLocationViewPermission: boolean;
    hasDispatcherNotesUpdatePermission: boolean;
    hasDispatcherNotesViewPermission: boolean;
    hasRegistrationInfoPermission: boolean;
    hasPartnerReservePermission: boolean;
    hasCompaniesViewPermission: boolean;
    hasNotesDeletePermission: boolean;
    hasNotesUpdatePermission: boolean;
    hasInitialLoadPermission: boolean;
    hasOwnReservePermission: boolean;
    hasNotesViewPermission: boolean;
    hasArchivePermission: boolean;
    hasRestorePermission: boolean;
    isInitialLoadSkipped: boolean;
    hasCreatePermission: boolean;
    hasUpdatePermission: boolean;
    hasViewPermission: boolean;
};

const useTrucksPermissions = (): UseTrucksPermissions => {
    const {isInitialLoadSkipped, hasInitialLoadPermission} = useInitialLoadWithPermission(
        PERMISSIONS.TRUCK_LIST_INITIAL_VIEW,
    );

    return {
        hasStatusAndLocationUpdatePermission: useCheckIfUserHasPermission(
            PERMISSIONS.TRUCK_MODALS_STATUS_AND_LOCATION_UPDATE,
        ),
        hasStatusAndLocationViewPermission: useCheckIfUserHasPermission(
            PERMISSIONS.TRUCK_MODALS_STATUS_AND_LOCATION_VIEW,
        ),
        hasDispatcherNotesUpdatePermission: useCheckIfUserHasPermission(PERMISSIONS.TRUCK_DISPATCHER_NOTES_UPDATE),
        hasDispatcherNotesViewPermission: useCheckIfUserHasPermission(PERMISSIONS.TRUCK_DISPATCHER_NOTES_VIEW),
        hasRegistrationInfoPermission: useCheckIfUserHasPermission(PERMISSIONS.TRUCK_REGISTRATION_INFO),
        hasPartnerReservePermission: useCheckIfUserHasPermission(PERMISSIONS.TRUCK_PARTNER_RESERVE),
        hasCompaniesViewPermission: useCheckIfUserHasPermission(PERMISSIONS.TRUCK_COMPANIES_VIEW),
        hasNotesDeletePermission: useCheckIfUserHasPermission(PERMISSIONS.TRUCK_NOTES_DELETE),
        hasNotesUpdatePermission: useCheckIfUserHasPermission(PERMISSIONS.TRUCK_NOTES_UPDATE),
        hasOwnReservePermission: useCheckIfUserHasPermission(PERMISSIONS.TRUCK_OWN_RESERVE),
        hasNotesViewPermission: useCheckIfUserHasPermission(PERMISSIONS.TRUCK_NOTES_VIEW),
        hasArchivePermission: useCheckIfUserHasPermission(PERMISSIONS.TRUCK_ARCHIVE),
        hasRestorePermission: useCheckIfUserHasPermission(PERMISSIONS.TRUCK_ARCHIVE),
        hasCreatePermission: useCheckIfUserHasPermission(PERMISSIONS.TRUCK_CREATE),
        hasUpdatePermission: useCheckIfUserHasPermission(PERMISSIONS.TRUCK_UPDATE),
        hasViewPermission: useCheckIfUserHasPermission(PERMISSIONS.TRUCK_VIEW),
        hasAllianceCompanyNameFieldViewPermission: useCheckIfUserHasPermission(
            PERMISSIONS.TRUCK_ALLIANCE_COMPANY_NAME_VIEW,
        ),
        isInitialLoadSkipped,
        hasInitialLoadPermission,
    };
};

export default useTrucksPermissions;
