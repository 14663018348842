import React from 'react';
import trimStart from 'lodash/trimStart';
import {Field, InjectedFormProps, reduxForm} from 'redux-form';

import Button from 'components/ui/Buttons/Button';
import TextInputWithAutoCompleteDropdown from 'components/ui/Form/TextInputWithAutoCompleteDropdown';

import {COMPANY_NAME_FIELD_MAX_LENGTH} from 'pages/Carriers/constants';
import useSelectCompaniesActions from 'pages/Owners/components/modals/SelectCompanies/hooks/useSelectCompaniesActions';
import useModalActions from 'pages/Owners/hooks/useModalActions';
import {SelectCompaniesModalFormValues} from 'pages/Owners/types/formTypes';

import {getTypeFieldNameFactory} from 'utils/typeScript';

import useChangeReduxFormValues from 'hooks/reduxForm/useChangeReduxFormValues';
import useGetReduxFormValues from 'hooks/reduxForm/useGetReduxFormValues';

import SelectCompaniesInfo from './components/SelectCompaniesInfo';
import SelectCompaniesSearchResult from './components/SelectCompaniesSearchResult';
import SelectCompaniesTable from './components/SelectCompaniesTable';

import styles from './styles.module.scss';

const getName = getTypeFieldNameFactory<SelectCompaniesModalFormValues>();

type OwnProps = {
    externalFormName: string;
};

const Form: React.FC<OwnProps & InjectedFormProps<{}, OwnProps>> = (props) => {
    const {handleSubmit, form, externalFormName} = props;

    const {saveSelectedCompanies, getSimilarCompaniesDropdown} = useSelectCompaniesActions();
    const formValues = useGetReduxFormValues<SelectCompaniesModalFormValues>(form);
    const changeFormValue = useChangeReduxFormValues(form);
    const {closeModal} = useModalActions();

    const getSimilarCompanies = (searchValue: string) => {
        return getSimilarCompaniesDropdown({formValues: {companyName: searchValue}});
    };

    const handleSave = () => {
        saveSelectedCompanies({formValues, formName: externalFormName});
        closeModal();
    };

    const resetSearchField = () => {
        changeFormValue({[getName('companyName')]: null});
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="modal-body__main">
                <div className={styles.searchRow}>
                    <div className={styles.searchField}>
                        <Field
                            component={TextInputWithAutoCompleteDropdown}
                            maxLength={COMPANY_NAME_FIELD_MAX_LENGTH}
                            normalize={(value) => trimStart(value)}
                            placeholder="enter company name"
                            fetchData={getSimilarCompanies}
                            name={getName('companyName')}
                            isHighlight={true}
                        />
                    </div>

                    <Button
                        disabled={!formValues.companyName}
                        onClick={resetSearchField}
                        buttonSize="icon"
                        colorTheme="grey"
                        buttonIcon="undo"
                    />

                    <Button type="submit" disabled={!formValues.companyName} colorTheme="green">
                        Apply and Search
                    </Button>
                </div>

                <SelectCompaniesSearchResult />

                <div className="bottom-hr mt5" />

                <SelectCompaniesInfo />

                <SelectCompaniesTable />
            </div>

            <div className="modal-body__bottom">
                <Button onClick={closeModal}>Cancel</Button>

                <Button colorTheme="blue" onClick={handleSave}>
                    Save
                </Button>
            </div>
        </form>
    );
};

export default reduxForm<{}, OwnProps>({})(Form);
