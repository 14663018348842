import {useEffect, useRef} from 'react';

import {PermissionsValues} from 'core/entities/Permissions';

import useCheckIfUserHasPermission from 'hooks/useCheckIfUserHasPermission';

type UseInitialLoadWithPermission = {
    hasInitialLoadPermission: boolean;
    isInitialLoadSkipped: boolean;
};

const useInitialLoadWithPermission = (permission: PermissionsValues): UseInitialLoadWithPermission => {
    const hasPermission = useCheckIfUserHasPermission(permission);

    const isInitialLoadSkippedRef = useRef(hasPermission);

    useEffect(() => {
        isInitialLoadSkippedRef.current = true;
    }, []);

    return {isInitialLoadSkipped: isInitialLoadSkippedRef.current, hasInitialLoadPermission: hasPermission};
};

export default useInitialLoadWithPermission;
