import {WEB_SOCKET_OWNERS_TRUCKS_UPDATE_RECEIVED} from 'store/middlewares/mainAPISocketsIO/entities/owners/actionTypes';
import {WEB_SOCKET_TRUCKS_COORDINATES_RECEIVED} from 'store/middlewares/mainAPISocketsIO/entities/trucks/actionTypes';

import {Dispatcher} from 'core/entities/Dispatcher/types';
import Owner from 'core/entities/Owner/types';
import {mergeTruckLocationWebsocketUpdate} from 'core/entities/Truck';

import * as tabs from 'pages/Owners/components/Forms/Tabs/tabConfig';

import Pagination from 'types/Pagination';

import * as types from '../actionTypes';
import * as mappers from '../mappers';

interface State {
    data: Owner | null;
    dispatchers: Dispatcher[];
    showResetFormModal: boolean;
    showSuccessModal: boolean;
    showUpdateModal: boolean;
    activeTab: string;
    companiesTab: {
        items: Owner['companies'];
        orderBy: 'asc' | 'desc' | null;
        pagination: Pagination;
    };
}

// exported for testing purposes only
export const defaultState: State = {
    data: null,
    dispatchers: [],

    showResetFormModal: false,
    showSuccessModal: false,
    showUpdateModal: false,

    activeTab: tabs.GENERAL.section,

    companiesTab: {
        items: [],
        orderBy: null,
        pagination: {totalItemsCount: 0, currentPage: 1, pagesCount: 1, perPage: 10},
    },
};

const owner = (state: State = defaultState, action): State => {
    switch (action.type) {
        case types.OWNER_RECEIVED: {
            return mappers.handleReducerByOwnerReceived({state, owner: action.payload});
        }

        case types.OWNER_COMPANIES_TAB_ITEMS_BY_PAGE_RECEIVED: {
            const {followingPage} = action.payload;

            return mappers.handleReducerByOwnerCompaniesTabItemsByPageReceived({state, followingPage});
        }

        case types.OWNER_COMPANIES_TAB_ITEMS_BY_PER_PAGE_RECEIVED: {
            const {perPage} = action.payload;

            return mappers.handleReducerByOwnerCompaniesTabItemsByPerPageReceived({state, perPage});
        }

        case types.OWNER_COMPANIES_TAB_ITEMS_ORDER_BY_RECEIVED: {
            return mappers.handleReducerByOwnerCompaniesTabItemsOrderByReceived({state});
        }

        case types.OWNER_ARCHIVED: {
            const {owner: archivedOwner} = action.payload as {owner: Owner};
            if (!state.data) {
                return state;
            }
            return {
                ...state,
                data: {...state.data, ...archivedOwner},
            };
        }

        case types.OWNER_GENERAL_NOTE_RECEIVED: {
            if (!state.data) {
                return state;
            }

            return {
                ...state,
                data: {
                    ...state.data,
                    generalNotes: [action.payload, ...(state.data?.generalNotes || [])],
                },
            };
        }

        case types.OWNER_GENERAL_NOTE_DELETED: {
            if (!state.data) {
                return state;
            }

            return {
                ...state,
                data: {
                    ...state.data,
                    generalNotes: state.data?.generalNotes?.filter?.((item) => item.id !== action.payload.id),
                },
            };
        }

        case WEB_SOCKET_OWNERS_TRUCKS_UPDATE_RECEIVED: {
            if (!state.data) {
                return state;
            }
            const updatedTruck = action.payload;
            return {
                ...state,
                data: {
                    ...state.data,
                    trucks: state.data.trucks.map((item) => {
                        if (item.id !== updatedTruck.id) {
                            return item;
                        }

                        return {
                            ...item,
                            ...updatedTruck,
                        };
                    }),
                },
            };
        }

        case WEB_SOCKET_TRUCKS_COORDINATES_RECEIVED: {
            if (!state.data) {
                return state;
            }
            const updatedLocation = action.payload;
            return {
                ...state,
                data: {
                    ...state.data,
                    trucks: state.data.trucks.map((item) => {
                        if (item.id !== updatedLocation.truck) {
                            return item;
                        }

                        return mergeTruckLocationWebsocketUpdate(item, updatedLocation);
                    }),
                },
            };
        }
        case types.TOGGLE_RESET_FORM_MODAL: {
            return {
                ...state,
                showResetFormModal: !state.showResetFormModal,
            };
        }
        case types.TOGGLE_CREATE_MODAL: {
            return {
                ...state,
                showSuccessModal: !state.showSuccessModal,
            };
        }
        case types.TOGGLE_UPDATE_MODAL: {
            return {
                ...state,
                showUpdateModal: !state.showUpdateModal,
            };
        }

        case types.DISPATCHERS_RECEIVED: {
            return {
                ...state,
                dispatchers: action.payload,
            };
        }

        case types.FORM_TAB_CHANGE:
            return {
                ...state,
                activeTab: action.payload.activeTab,
            };

        case types.CLEAR_STATE:
            return {...defaultState};

        default:
            return state;
    }
};

export default owner;
