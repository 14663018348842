import React from 'react';
import compact from 'lodash/compact';

import useSelectCompaniesState from 'pages/Trucks/components/modals/SelectCompanies/hooks/useSelectCompaniesState';
import {SELECT_COMPANIES_FORM_NAME} from 'pages/Trucks/constants';
import fields from 'pages/Trucks/constants/fields';
import {SelectCompaniesModalFormValues} from 'pages/Trucks/types/formTypes';

import {getTypeFieldNameFactory} from 'utils/typeScript';

import useChangeReduxFormValues from 'hooks/reduxForm/useChangeReduxFormValues';
import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import styles from './styles.module.scss';

const getName = getTypeFieldNameFactory<SelectCompaniesModalFormValues>();

type OwnProps = {
    externalFormName: string;
};

const SelectCompaniesInfo: React.FC<OwnProps> = (props) => {
    const {externalFormName} = props;

    const selectedCompaniesFormValue = useGetReduxFormValue(SELECT_COMPANIES_FORM_NAME, getName('selectedCompanies'));
    const ownerFormValue = useGetReduxFormValue(externalFormName, fields.general.owner);
    const changeFormValues = useChangeReduxFormValues(SELECT_COMPANIES_FORM_NAME);
    const {allAllianceCount} = useSelectCompaniesState();

    const handleClearSelection = () => {
        changeFormValues({
            [getName('isAllCompaniesSelected')]: null,
            [getName('selectedCompanies')]: null,
        });
    };

    return (
        <div className="mt20">
            <span className={styles.title}>The truck operates for alliance companies:</span>

            <span className={styles.description}>Select only from companies partnered with the truck owner</span>

            <div className="mt10 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center gap10">
                    <span>
                        All Alliance companies: <strong>{allAllianceCount}</strong>
                    </span>

                    <span className={styles.dot} />

                    <span>
                        Owner work with: <strong>{ownerFormValue?.value?.companies?.length || 0}</strong>
                    </span>

                    <span className={styles.dot} />

                    <span>
                        Truck work with: <strong>{compact(selectedCompaniesFormValue)?.length || 0}</strong>
                    </span>
                </div>

                <div className={styles.clear} onClick={handleClearSelection}>
                    Clear selection
                </div>
            </div>
        </div>
    );
};

export default SelectCompaniesInfo;
