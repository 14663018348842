import isUndefined from 'lodash/isUndefined';

import Carrier from 'core/entities/Carrier/types';

import {SELECT_COMPANIES_FORM_NAME} from 'pages/Trucks/constants';

import useGetReduxFormInitialValues from 'hooks/reduxForm/useGetReduxFormInitialValues';

import useSelectCompaniesState from './useSelectCompaniesState';

type Params = {company?: Carrier; index?: number; fromInitial?: boolean};

type SyncItem = {label: string; value: number} | null;

type UseSyncTableRows = {
    isCompanyLoadPlannedOrInProgress: boolean;
    isCompanyNotAvailable: boolean;
    sync: SyncItem[];
};

const useSyncTableRows = (params: Params): UseSyncTableRows => {
    const {company, index, fromInitial} = params;

    const {selectedCompanies} = useGetReduxFormInitialValues(SELECT_COMPANIES_FORM_NAME);
    const {companies, initialCompanies} = useSelectCompaniesState();

    const sourceCompanies = fromInitial ? initialCompanies : companies;

    const sync: SyncItem[] = (sourceCompanies || []).map((serverItem) => {
        const isMatch = selectedCompanies?.some((frontItem) => frontItem?.value === serverItem.id);

        return isMatch ? {label: serverItem.company_name, value: serverItem.id} : null;
    });

    const checkIsCompanyNotAvailable = () => {
        if (isUndefined(index) || !company) {
            return false;
        }

        return sync[index]?.value !== company.id;
    };

    // TODO: REX-11310
    // const isCompanyLoadPlannedOrInProgress = !isCompanyNotAvailable && '<add condition here>';
    const isCompanyLoadPlannedOrInProgress = false;

    return {
        isCompanyNotAvailable: checkIsCompanyNotAvailable(),
        isCompanyLoadPlannedOrInProgress,
        sync,
    };
};

export default useSyncTableRows;
