import React from 'react';
import classNames from 'classnames';

import {getCarrierPhysicalAddressAllInfo} from 'core/entities/Carrier/modules/carrierAddress';
import Carrier from 'core/entities/Carrier/types';

import useSyncTableRows from 'pages/Trucks/components/modals/SelectCompanies/hooks/useSyncTableRows';

import {getCityLineFromObject} from 'utils/getCityLine';

import CompanyNameInfo from './components/CompanyNameInfo';

import styles from './styles.module.scss';

type OwnProps = {
    company: Carrier;
    index: number;
};

const SelectCompaniesTableRow: React.FC<OwnProps> = (props) => {
    const {company, index} = props;

    const {isCompanyNotAvailable} = useSyncTableRows({company, index});

    const {
        carrierPhysicalCountry,
        carrierPhysicalState,
        carrierPhysicalCity,
        carrierPhysicalZip,
    } = getCarrierPhysicalAddressAllInfo(company);

    const physicalAddress = getCityLineFromObject({
        country: carrierPhysicalCountry,
        state: carrierPhysicalState,
        city: carrierPhysicalCity,
        zip: carrierPhysicalZip,
    });

    return (
        <tr className={classNames(styles.row, {[styles.row__disabled]: isCompanyNotAvailable})}>
            <td>
                <CompanyNameInfo company={company} index={index} />
            </td>

            <td>
                <div>{company.scac}</div>
            </td>

            <td>
                <div>{physicalAddress}</div>
            </td>

            <td>
                <div>{company.mc_number}</div>
            </td>
        </tr>
    );
};

export default React.memo(SelectCompaniesTableRow);
