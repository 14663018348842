import {useSelector} from 'react-redux';

import * as selectors from 'store/reducers/appSettings/selectors';

type UseDomainSettings = {
    isDispatchlandRecruitingAgency: ReturnType<typeof selectors.checkIsDispatchlandRecruitingAgency>;
    isSidebarExpanded: ReturnType<typeof selectors.checkIsSidebarExpanded>;
    companyShortName: ReturnType<typeof selectors.getCompanyShortTitle>;
    companyFullName: ReturnType<typeof selectors.getCompanyFullTitle>;
    isSendQuote: ReturnType<typeof selectors.checkIsSendQuote>;
    sidebarMenu: ReturnType<typeof selectors.getSidebarMenu>;
    urlLogo: ReturnType<typeof selectors.getDomainLogo>;
};

const useDomainSettings = (): UseDomainSettings => ({
    isDispatchlandRecruitingAgency: useSelector(selectors.checkIsDispatchlandRecruitingAgency),
    isSidebarExpanded: useSelector(selectors.checkIsSidebarExpanded),
    companyShortName: useSelector(selectors.getCompanyShortTitle),
    companyFullName: useSelector(selectors.getCompanyFullTitle),
    isSendQuote: useSelector(selectors.checkIsSendQuote),
    sidebarMenu: useSelector(selectors.getSidebarMenu),
    urlLogo: useSelector(selectors.getDomainLogo),
});

export default useDomainSettings;
