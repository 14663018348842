import React from 'react';

import {getDriverName} from 'core/entities/Driver/modules/driverName';
import Driver from 'core/entities/Driver/types';
import Truck from 'core/entities/Truck/types';

import {TruckLink} from 'components/ui/Links';

const FirstDriverWarning: React.FC<{driver: Driver; isDriverMain: boolean; truck: Truck}> = (props) => {
    const {driver, isDriverMain, truck, children} = props;
    const driverName = getDriverName(driver);
    const firstDriverDesc = isDriverMain ? 'The driver is first driver and main contact' : 'The driver is first driver';
    return (
        <div>
            {children}
            <p>
                Archiving <strong>{driverName}</strong> driver`s profile will deactivate any actions with it. If needed,
                you will still have the ability to restore the profile.
                <br />
                <br />
                {firstDriverDesc} on{' '}
                <strong>
                    <TruckLink truck={truck} content={`truck # ${truck.number}`} className="second-link" />.
                </strong>{' '}
                Please, if you want to attach this truck for the load don`t forget add the first driver and main contact
                person.
            </p>
        </div>
    );
};

export default FirstDriverWarning;
