import React from 'react';

import useDomainSettings from 'hooks/useDomainSettings';

import {useSupportItem} from './hooks';

const SupportItem: React.FC = () => {
    const {openSupportModal, email} = useSupportItem();
    const {isSidebarExpanded} = useDomainSettings();

    return (
        <div className="support-item">
            <div className="support-item-entry">
                <a onClick={openSupportModal} className="support-item-entry__title">
                    Contact Support <i className="fa fa-commenting" />
                </a>
                {email && <div className="support-item-entry__phone">{email}</div>}
            </div>
            {!isSidebarExpanded && <i onClick={openSupportModal} className="fa fa-commenting commenting-icon" />}
        </div>
    );
};

export default SupportItem;
