import React, {useEffect} from 'react';
import {Field, InjectedFormProps, reduxForm} from 'redux-form';

import {TRUCK_STATUS_NOT_AVAILABLE} from 'core/entities/Truck/constants/truckStatuses';
import {isTruckStatusOnRoute} from 'core/entities/Truck/statuses';
import Truck from 'core/entities/Truck/types';

import TruckPreferredLoadsSelect from 'components/common/Truck/TruckPreferredLoads/components/TruckPreferredLoadsSelect';
import Button from 'components/ui/Buttons/Button';
import TextInputWithDatePicker from 'components/ui/Form/InputWithDatePicker';
import LocationFields from 'components/ui/Form/LocationFields';
import SelectTimeZone from 'components/ui/Form/SelectTimeZone';
import SingleSelect from 'components/ui/Form/SingleSelect';
import TimeInput from 'components/ui/Form/TimeInput';

import {createInitFormValues} from 'pages/Trucks/components/modals/UpdateStatusModal/utils';
import useModalActions from 'pages/Trucks/hooks/useModalActions';
import useTrucksPermissions from 'pages/Trucks/hooks/useTrucksPermissions';
import {UpdateStatusModalFormValues} from 'pages/Trucks/types/formTypes';

import {getTypeFieldNameFactory} from 'utils/typeScript';

import useChangeReduxFormValues from 'hooks/reduxForm/useChangeReduxFormValues';
import useGetReduxFormValue from 'hooks/reduxForm/useGetReduxFormValue';

import {PlaceAddress} from 'types/Address';

import {statusOptions} from './constants';

const getName = getTypeFieldNameFactory<UpdateStatusModalFormValues>();

type OwnProps = {
    truck: Truck;

    onClose: () => void;
};

const Form: React.FC<OwnProps & InjectedFormProps<{}, OwnProps>> = (props) => {
    const {handleSubmit, form, truck, onClose} = props;

    const {number, status} = truck;
    const statusFieldName = getName('status');

    const {hasStatusAndLocationUpdatePermission} = useTrucksPermissions();
    const selectedStatus = useGetReduxFormValue(form, statusFieldName);
    const changeFormValues = useChangeReduxFormValues(form);
    const {changeTimeZoneByCoordinates} = useModalActions();

    const isTruckOnRoute = isTruckStatusOnRoute(status);

    const handleLocationSelect = (data: PlaceAddress) => {
        changeTimeZoneByCoordinates({formName: form, coordinates: data.coords});
    };

    useEffect(() => {
        if (selectedStatus.value === TRUCK_STATUS_NOT_AVAILABLE) {
            const formValues = createInitFormValues({truck, selectedNotAvailableTruckStatus: true});

            changeFormValues(formValues);
        }
    }, [selectedStatus?.value]);

    const chooseTruckStatusLabel = (
        <>
            <strong>TRUCK#{number} </strong>STATUS
        </>
    );

    const selectStatusOptions = JSON.parse(JSON.stringify(statusOptions));
    const isFormDisabled = isTruckOnRoute || !hasStatusAndLocationUpdatePermission;

    return (
        <form onSubmit={handleSubmit}>
            <div className="p20 pb15">
                <div className="row">
                    <div className="col-sm-6">
                        <SingleSelect
                            placeholder="choose truck status"
                            label={chooseTruckStatusLabel}
                            options={selectStatusOptions}
                            isDisabled={isFormDisabled}
                            name={statusFieldName}
                        />
                    </div>

                    <div className="col-sm-6">
                        <TruckPreferredLoadsSelect isDisabled={isFormDisabled} name={getName('preferredLoads')} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-8">
                        <Field
                            longitudeFieldName={`${getName('location')}.longitude`}
                            latitudeFieldName={`${getName('location')}.latitude`}
                            countryFieldName={`${getName('location')}.country`}
                            stateFieldName={`${getName('location')}.state`}
                            cityFieldName={`${getName('location')}.city`}
                            zipFieldName={`${getName('location')}.zip`}
                            name={`${getName('location')}.cityLine`}
                            onSelectLocation={handleLocationSelect}
                            component={LocationFields}
                            isDisabled={isFormDisabled}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <Field
                            name={`${getName('truckDateTime')}.date`}
                            component={TextInputWithDatePicker}
                            disabled={isFormDisabled}
                            label="Date"
                        />
                    </div>

                    <div className="col-md-4">
                        <Field
                            name={`${getName('truckDateTime')}.time`}
                            disabled={isFormDisabled}
                            component={TimeInput}
                            placeholder="time"
                            label="Time"
                        />
                    </div>

                    <div className="col-md-4">
                        <Field
                            name={`${getName('truckDateTime')}.timeZone`}
                            component={SelectTimeZone}
                            isDisabled={isFormDisabled}
                            isClearable={false}
                            label="Time zone"
                            withLongLabel
                        />
                    </div>
                </div>
            </div>

            <div className="modal-body__bottom">
                <Button colorTheme="grey" onClick={onClose}>
                    Cancel
                </Button>

                <Button type="submit" colorTheme="blue" disabled={isFormDisabled}>
                    Change
                </Button>
            </div>
        </form>
    );
};

export default reduxForm<{}, OwnProps>({})(Form);
