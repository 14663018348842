import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import {logout} from 'store/actions';
import appSettingsActions from 'store/reducers/appSettings/actionCreators';

import getDispatcherName from 'core/entities/Dispatcher/getDispatcherName';

import ChatToggle from 'widgets/Chat/components/common/ChatToggle';
import Notifications from 'widgets/Notifications';

import {DispatcherLink} from 'components/ui/Links';

import getFilePath from 'utils/getFilePath';
// import AnimateTransportation from 'celebration/NewYear/components/AnimateTransportation'; // animate for #celebrationNewYear theme
// import {getCelebrationState} from 'store/reducers/celebration/selectors'; // animate for #celebrationNewYear theme
// import ActionButton from 'celebration/NewYear/components/ActionButton'; // animate for #celebrationNewYear theme
// import SnowFall from 'celebration/NewYear/components/SnowFall'; // animate for #celebrationNewYear theme
// import * as types from 'store/actionTypes'; // animate for #celebrationNewYear theme
import importStyles from 'utils/importStyles';

import useCurrentDispatcher from 'hooks/useCurrentDispatcher';
import useCurrentRegion from 'hooks/useCurrentRegion';
import useDomainSettings from 'hooks/useDomainSettings';

import CurrentTime from './components/CurrentTime';
import dispatcherAvatar from './images/dispatcher-avatar.png';
import pointer from './images/open-pointer.svg';

importStyles('components/layout/TopBar/style');

const TopBar: React.FC = () => {
    const [myProfileDropdownExpanded, setMyProfileDropdownExpanded] = useState(false);
    const {urlLogo, companyShortName, isSidebarExpanded} = useDomainSettings();
    const {currentDispatcher} = useCurrentDispatcher();
    const {isNorthAmerica} = useCurrentRegion();
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(logout());
    };

    const toggleSidebar = (value) => {
        dispatch(appSettingsActions.toggleSidebar(value));
    };

    const toggleMyProfileDropdown = () => {
        setMyProfileDropdownExpanded((expanded) => !expanded);
    };

    const imageSrc = getFilePath(currentDispatcher.avatar) || dispatcherAvatar;
    const userName = getDispatcherName(currentDispatcher);

    // animate for #celebrationNewYear theme - START;
    // const isCelebration = useSelector(getCelebrationState);
    // const dispatch = useDispatch();
    // const actionTitle = isCelebration ? 'off' : 'on';
    // const changeSnow = () => ({type: types.CELEBRATION_CHANGE_SNOW, payload: !isCelebration});
    // const onAnimate = () => ({type: types.CELEBRATION_CALL_ANIMATION, payload: true});
    // animate for #celebrationNewYear theme - END;

    return (
        <>
            {/* animate for #celebrationNewYear theme - START */}
            {/* <AnimateTransportation /> */}
            {/* <SnowFall /> */}
            {/* animate for #celebrationNewYear theme - END */}

            <div className={`header-wrap ${isSidebarExpanded ? '' : 'leftbar-closed'}`}>
                <div className="pull-left">
                    <button
                        id="menu-btn"
                        className="menu-btn"
                        onClick={() => {
                            toggleSidebar(isSidebarExpanded);
                        }}
                    >
                        ☰
                    </button>
                </div>
                <div className="pull-right d-flex align-items-center">
                    {/* animate for #celebrationNewYear theme - START */}
                    {/* {isCelebration ? <ActionButton activeState={null} onClick={() => dispatch(onAnimate())} /> : null} */}
                    {/* <ActionButton activeState={actionTitle} onClick={() => dispatch(changeSnow())} /> */}
                    {/* animate for #celebrationNewYear theme - END */}

                    <CurrentTime />

                    {!isEmpty(urlLogo) ? (
                        <div>
                            <img className="company-logo" src={urlLogo} alt={companyShortName} />
                        </div>
                    ) : null}

                    <div
                        id="user-wrap"
                        className={`user-wrap${myProfileDropdownExpanded ? ' user-logged-expanded' : ''}`}
                    >
                        <a onClick={toggleMyProfileDropdown} id="user-logged" className="logged-user">
                            <img alt="" id="user-img" className="user-img" src={imageSrc} />

                            <span id="logged-user-name" className="logged-user-name">
                                {userName}
                            </span>
                        </a>
                        <img
                            src={pointer}
                            alt="Kiwi standing on oval"
                            className="open-pointer"
                            style={{display: myProfileDropdownExpanded ? 'block' : 'none'}}
                        />
                        <div id="user-profile-settings" className="user-profile-settings">
                            <div className="user-setting-header">
                                <div className="header-text pull-left">
                                    <h3>MY PROFILE SETTINGS</h3>
                                </div>
                                <button
                                    onClick={toggleMyProfileDropdown}
                                    id="user-close-btn"
                                    className="menu-btn pull-right"
                                >
                                    <i className="fa fa-times" aria-hidden="true" />
                                </button>
                            </div>

                            <div className="user-settings-items">
                                <ul>
                                    <li>
                                        <span style={{marginLeft: 20}}>
                                            <DispatcherLink
                                                dispatcher={currentDispatcher}
                                                className="second-link"
                                                content={userName}
                                            />
                                        </span>
                                    </li>
                                    <li>
                                        <a onClick={handleLogout} className="user-settings-items-item">
                                            <i className="fa fa-power-off" aria-hidden="true" />
                                            &nbsp;&nbsp;Log out
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="user-border-bottom" />
                        </div>
                    </div>

                    {isNorthAmerica ? <Notifications /> : null}

                    <ChatToggle />
                </div>
            </div>
        </>
    );
};

export default TopBar;
