import head from 'lodash/head';
import toNumber from 'lodash/toNumber';

import {getDistanceSegments, getTotalDistance as getLoadTotalDistance} from 'core/entities/Load/modules/loadDistance';
import {getLoadRate} from 'core/entities/Load/modules/loadRate';
import Load from 'core/entities/Load/types';
import TravelOrder from 'core/entities/TravelOrder/types';

import {CurrencyValue} from 'utils/data/currency';

export const getLoadFromTravelOrder = (travelOrder?: TravelOrder | null): Load | undefined => head(travelOrder?.loads);

export const getLoadsFromTravelOrder = (travelOrder?: TravelOrder | null): Load[] => travelOrder?.loads || [];

export const getLoadsNumbersFromTravelOrder = (travelOrder?: TravelOrder | null): number[] => {
    const loads = getLoadsFromTravelOrder(travelOrder);

    return loads.map((item: Load) => item.number);
};

export const getLoadNotesFromTravelOrder = (travelOrder?: TravelOrder | null): Load['notes'] => {
    const load = getLoadFromTravelOrder(travelOrder);

    return load?.notes || [];
};

export const getLoadDistance = (travelOrder?: TravelOrder | null): number => {
    const load = getLoadFromTravelOrder(travelOrder);

    return getLoadTotalDistance(load);
};

export const getLoadDistanceSegments = (travelOrder?: TravelOrder | null): number[] => {
    const load = getLoadFromTravelOrder(travelOrder);

    return getDistanceSegments(load);
};

type LoadPayData = {totalDistance: number; totalCharges: number; currency?: CurrencyValue};

export const getLoadPayData = (load?: Load | null): LoadPayData => {
    const rate = getLoadRate(load);
    const totalDistance = toNumber(getLoadTotalDistance(load || undefined) || 0);
    const totalCharges = toNumber(rate?.amount || 0);
    const currency = rate?.currency;

    return {totalDistance, totalCharges, currency};
};

export const getLoadPayDataFromTravelOrder = (travelOrder?: TravelOrder | null): LoadPayData => {
    const load = getLoadFromTravelOrder(travelOrder);
    return getLoadPayData(load);
};
