import {getEntityFieldName} from 'core/entities/Owner/types';

export default {
    general: {
        id: getEntityFieldName('id'),
        full_name: getEntityFieldName('full_name'),
        date_of_birthday: getEntityFieldName('date_of_birthday'),
        country_of_birth: getEntityFieldName('country_of_birth'),
        citizenship: getEntityFieldName('citizenship'),
        status: getEntityFieldName('status'),
        language: getEntityFieldName('language'),
        not_working_with: getEntityFieldName('not_working_with'),

        hired_by: getEntityFieldName('hired_by'),
        hired_date: getEntityFieldName('hired_date'),
        document: getEntityFieldName('document'),
        document_number: getEntityFieldName('document_number'),
        document_company_name: getEntityFieldName('document_company_name'),

        policy_number: getEntityFieldName('policy_number'),
        policy_eff: getEntityFieldName('policy_eff'),
        policy_exp: getEntityFieldName('policy_exp'),
        is_deleted: getEntityFieldName('is_deleted'),

        alliance_company_name: getEntityFieldName('alliance_company_name'),
        newGeneralNote: 'newGeneralNote',
        workWithCompanies: 'workWithCompanies',
    },
    contact: {
        address_line: getEntityFieldName('address_line'),
        additional_address_line: getEntityFieldName('additional_address_line'),
        city: getEntityFieldName('city'),
        state: getEntityFieldName('state'),
        zip: getEntityFieldName('zip'),
        country: getEntityFieldName('country'),

        mobile_phone: getEntityFieldName('mobile_phone'),
        additional_mobile_phone: getEntityFieldName('additional_mobile_phone'),
        home_phone: getEntityFieldName('home_phone'),
        home_lat: getEntityFieldName('home_lat'),
        home_lng: getEntityFieldName('home_lng'),
        email: getEntityFieldName('email'),
        is_send_owner: 'is_send_owner',

        ec_name: getEntityFieldName('ec_name'),
        relationship: getEntityFieldName('relationship'),
        ec_phone: getEntityFieldName('ec_phone'),

        owner_with_coordinator: getEntityFieldName('owner_with_coordinator'),
        coordinator_full_name: getEntityFieldName('coordinator_full_name'),
        coordinator_language: getEntityFieldName('coordinator_language'),
        coordinator_mobile_phone: getEntityFieldName('coordinator_mobile_phone'),
        coordinator_additional_mobile_phone: getEntityFieldName('coordinator_additional_mobile_phone'),
        coordinator_email: getEntityFieldName('coordinator_email'),
        is_send_coordinator: 'is_send_coordinator',
        coordinator_ec_name: getEntityFieldName('coordinator_ec_name'),
        coordinator_ec_relationship: getEntityFieldName('coordinator_ec_relationship'),
        coordinator_ec_contact_phone: getEntityFieldName('coordinator_ec_contact_phone'),
    },
    custom: {
        addressLocation: 'addressLocation',
    },
};
