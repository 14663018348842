import {useDispatch, useSelector} from 'react-redux';

import {closeModal} from 'components/ui/ModalProvider/actions';
import {getCurrentModalID} from 'components/ui/ModalProvider/selectors';

import * as selectCompaniesActions from 'pages/Owners/redux/actions/selectCompanies';

import * as modalActions from '../redux/actions/modals';

type UseModalActions = {
    showSelectCompaniesModal: (params: {formName: string}) => void;
    getInitialSelectCompaniesList: () => void;
    closeModal: () => void;
};

const useModalActions = (): UseModalActions => {
    const currentModalID = useSelector(getCurrentModalID);
    const dispatch = useDispatch();

    return {
        getInitialSelectCompaniesList: () => dispatch(selectCompaniesActions.getInitialSelectCompaniesList()),
        showSelectCompaniesModal: (params) => dispatch(modalActions.showSelectCompaniesModal(params)),
        closeModal: () => dispatch(closeModal(currentModalID)),
    };
};

export default useModalActions;
