import omit from 'lodash/omit';

import {AppSettings, SidebarItems} from 'core/entities/AppSettings/types';

import type {AppSettingsActionsTypes} from './actionCreators';
import * as types from './actionTypes';
import {dateFormatsMap, getMenuItems, timeFormatsMap} from './utils';

export type AppSettingsState = Omit<AppSettings, 'menuItem'> & {
    sidebarMenu: SidebarItems;
    isSidebarExpanded: boolean;
    updatingTravelOrdersTime: number;
    mapApiKey: {
        id: number;
        key: string | null;
        email: string | null;
    };
};

const defaultState: AppSettingsState = {
    date_format: 'MM/dd/yyyy',
    time_format: 'HH:mm',
    timezone: 'America/New_York',
    map_region: 'na',
    survey_is_enabled: true,
    company_full_title: '',
    company_short_title: '',
    is_send_quote: false,
    isDispatchlandRecruitingAgency: false,
    is_show_partners_tab: false,
    domainLogo: '',
    distance_format: 'mi',
    default_weight_format: 'lbs',
    default_dimensions_format: 'in',
    current_version: '',
    support: {
        support_email: null,
        support_phone: null,
    },
    sidebarMenu: [],
    isSidebarExpanded: false,
    updatingTravelOrdersTime: 5,
    mapApiKey: {
        id: 1,
        key: null,
        email: null,
    },
};

const appSettings = (state: AppSettingsState = defaultState, action: AppSettingsActionsTypes): AppSettingsState => {
    switch (action.type) {
        case types.SET_APP_SETTINGS: {
            return {
                ...state,
                ...omit(action.payload, ['menuItem', 'date_format', 'time_format']),
                date_format: dateFormatsMap[action.payload.date_format],
                time_format: timeFormatsMap[action.payload.time_format],
                sidebarMenu: getMenuItems(action.payload.menuItem),
            };
        }

        case types.SET_SUPPORT_DATA: {
            return {
                ...state,
                support: action.payload,
            };
        }

        case types.TOGGLE_SIDEBAR: {
            return {
                ...state,
                isSidebarExpanded: action.payload,
            };
        }

        case types.SET_TRAVEL_ORDERS_UPDATING_TIME: {
            return {
                ...state,
                updatingTravelOrdersTime: action.payload,
            };
        }

        case types.SET_MAP_API_KEYS:
            return {
                ...state,
                mapApiKey: action.payload,
            };

        // no default
    }

    const _exhaustiveCheck: never = action;

    return state;
};

export default appSettings;
