import compact from 'lodash/compact';

import {InferActions} from 'store';

import Carrier from 'core/entities/Carrier/types';

import {selectCompaniesActionCreators} from 'pages/Owners/redux/actions/selectCompanies';
import * as actionTypes from 'pages/Owners/redux/actionTypes/selectCompanies';
import * as mappers from 'pages/Owners/redux/mappers/selectCompanies';

type ActionTypes = InferActions<typeof selectCompaniesActionCreators>;

export type SelectCompaniesState = {
    searchParams: {companyName: string} | null;
    initialCompanies: Carrier[] | null; // we need to keep it due to sync of checkboxes order
    selectedCompaniesCount: number;
    companies: Carrier[] | null;
    searchMatchCount: number;
    allAllianceCount: number;
};

const defaultState: SelectCompaniesState = {
    selectedCompaniesCount: 0,
    initialCompanies: null,
    searchMatchCount: 0,
    allAllianceCount: 0,
    searchParams: null,
    companies: null,
};

const selectCompaniesModal = (state = defaultState, action: ActionTypes): SelectCompaniesState => {
    switch (action.type) {
        case actionTypes.INITIAL_COMPANIES_RECEIVED: {
            return mappers.handleReducerByInitialCompaniesReceived({state, companies: action.payload.companies});
        }

        case actionTypes.COMPANIES_BY_SORTING_RECEIVED: {
            return mappers.handleReducerByCompaniesBySortingReceived({state, companies: action.payload.companies});
        }

        case actionTypes.COMPANIES_BY_FILTERS_RECEIVED: {
            return mappers.handleReducerByCompaniesByFiltersReceived({state, filters: action.payload.filters});
        }

        case actionTypes.COMPANIES_SEARCH_PARAMS_RECEIVED: {
            return mappers.handleReducerByCompaniesSearchParamsReceived({state, filters: action.payload.filters});
        }

        case actionTypes.SELECTED_COMPANIES_COUNT_SET: {
            return {...state, selectedCompaniesCount: compact(action.payload.formValues.selectedCompanies).length};
        }

        case actionTypes.COMPANIES_STATE_PARTIALLY_CLEARED: {
            return {
                ...defaultState,
                selectedCompaniesCount: state.selectedCompaniesCount,
                allAllianceCount: state.allAllianceCount,
                initialCompanies: state.initialCompanies,
            };
        }

        case actionTypes.COMPANIES_STATE_FULLY_CLEARED: {
            return {...defaultState};
        }

        // no default
    }

    const _exhaustiveCheck = action;

    return state;
};

export default selectCompaniesModal;
