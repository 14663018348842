import {TRUCK_RESERVE_NOTE_UPDATED} from 'store/actionTypes';
import * as websocketsTypes from 'store/middlewares/mainAPISocketsIO/entities/trucks/actionTypes';

import Truck from 'core/entities/Truck/types';
import {Note} from 'core/entities/Truck/types/Note';

import * as types from 'pages/Trucks/redux/actionTypes';

import Pagination from 'types/Pagination';

interface State {
    items: Truck[];
    searchParams: {[key: string]: any};
    pagination: Partial<Pagination>;
    expandedRows: number[];
    drawerTruckNotes: {
        drawerExpand: boolean;
        drawerTruckData: Truck | null;
    };
    generalTruckNotes: Note[];
}

const addExpandedRowID = (rows, id) => {
    if (rows.includes(id)) {
        return rows.filter((item) => item !== id);
    }

    return [...rows, id];
};

// exported for testing purposes
export const defaultState = {
    items: [],
    searchParams: {},
    pagination: {
        currentPage: 1,
        perPage: 50,
    },
    expandedRows: [],
    drawerTruckNotes: {
        drawerExpand: false,
        drawerTruckData: null,
    },
    generalTruckNotes: [],
};

export default function (state: State = defaultState, action): State {
    switch (action.type) {
        case types.RECEIVE_TRUCKS:
            return {
                ...state,
                items: action.payload.trucks.map((truck) => ({
                    ...truck,
                    driver_note: (truck.driver_note && decodeURIComponent(truck.driver_note)) || null,
                })),
                pagination: action.payload.pagination,
            };

        case types.TRUCK_CLEAR_LIST_ITEMS:
            return {
                ...state,
                items: [],
                expandedRows: [],
            };

        case types.TRUCK_ARCHIVED: {
            const {truck: archivedTruck} = action.payload;

            return {
                ...state,
                items: state.items.filter((truck) => truck.id !== archivedTruck.id),
            };
        }

        case types.TRUCK_TOGGLE_ROW:
            return {
                ...state,
                expandedRows: addExpandedRowID(state.expandedRows, action.payload.truckID),
            };

        case types.TRUCKS_SET_CURRENT_PAGE:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    currentPage: action.payload,
                },
            };

        case types.TRUCKS_SET_PAGINATION:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    ...action.payload,
                },
            };

        case types.TRUCK_TOGGLE_ALL_ROWS:
            return {
                ...state,
                expandedRows: action.payload.rowsIds,
            };

        case types.TRUCKS_SET_SEARCH_PARAMS:
            return {
                ...state,
                searchParams: {...action.payload.searchParams},
            };

        case types.TRUCK_DISPATCHER_NOTE_UPDATED:
        case TRUCK_RESERVE_NOTE_UPDATED: {
            const {truck: updatedTruck, note: updatedNote} = action.payload;
            // for case when we have update notes from another page
            if (state.items.length === 0) {
                return state;
            }
            return {
                ...state,
                items: state.items.map((truck) => {
                    if (updatedTruck.id !== truck.id) {
                        return truck;
                    }
                    return {...truck, ...updatedNote};
                }),
                drawerTruckNotes: {
                    drawerExpand: true,
                    drawerTruckData: {...updatedTruck, ...updatedNote},
                },
            };
        }

        case types.TRUCK_NOTE_ADDED: {
            const newNote = action.payload;
            return {
                ...state,
                generalTruckNotes: [newNote, ...state.generalTruckNotes],
            };
        }

        case websocketsTypes.WEB_SOCKET_TRUCKS_RESERVE_RECEIVED:
            return {
                ...state,
                items: state.items.map((truck) => {
                    if (truck.id === action.payload.truckId) {
                        return {...truck, reserve: action.payload};
                    }

                    return truck;
                }),
            };

        case websocketsTypes.WEB_SOCKET_TRUCKS_RESERVE_CANCELED:
            return {
                ...state,
                items: state.items.map((truck) => {
                    if (truck.id === action.payload) {
                        return {
                            ...truck,
                            reserve: null,
                        };
                    }
                    return truck;
                }),
            };

        case websocketsTypes.WEB_SOCKET_TRUCKS_UPDATE_RECEIVED:
            return {
                ...state,
                items: state.items.map((truck) => {
                    const updatedTruck = action.payload;

                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (parseInt(truck.id, 10) === parseInt(updatedTruck.id, 10)) {
                        return {
                            ...truck,
                            ...updatedTruck,
                            dispatcher_note: updatedTruck.dispatcher_note || null,
                            additional_equipment: updatedTruck.additional_equipment || null,
                        };
                    }

                    return truck;
                }),
            };

        case websocketsTypes.WEB_SOCKET_TRUCKS_COORDINATES_RECEIVED: {
            const newCoords = action.payload;

            return {
                ...state,
                items: state.items.map((truck) => {
                    if (truck.id === newCoords.truck) {
                        return {
                            ...truck,
                            last_city_line: newCoords.city_line || truck.last_city_line,
                            updated_at: newCoords.time,
                            coordinates: [
                                {
                                    ...newCoords,
                                    truck_id: newCoords.truck,
                                    city_line: newCoords.city_line,
                                },
                            ],
                        };
                    }
                    return truck;
                }),
            };
        }

        case types.TRUCK_STATUS_UPDATE:
            // truck status will update twice because of manual update and websocket update
            return {
                ...state,
                items: state.items.map((item) => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    }

                    return item;
                }),
            };

        case types.CLEAR_STATE: {
            return defaultState;
        }

        case types.TOGGLE_DRAWER_EXPAND: {
            return {
                ...state,
                drawerTruckNotes: {
                    drawerExpand: action.payload.toggleData.drawerExpand,
                    drawerTruckData: action.payload.toggleData.truck,
                },
            };
        }

        default:
            return state;
    }
}
