import React from 'react';

import Truck from 'core/entities/Truck/types';

import ArchivingErrorWrapper from 'components/ui/modals/ArchivingErrorWrapper';

type OwnProps = {
    data: {truck: Truck};
    onClose();
};

const ArchivingError = (props: OwnProps): JSX.Element => {
    const {
        data: {truck},
        onClose,
    } = props;

    const truckNumber = `truck #${truck.number}`;

    return (
        <ArchivingErrorWrapper title={truckNumber} onClose={onClose}>
            <div>
                <p>
                    The <strong>{truckNumber}</strong> has loads <strong>In progress</strong> or{' '}
                    <strong>Planned</strong> statuses. Archiving the truck is not possible. Please, wait until the
                    Travel Order is completed.
                </p>
            </div>
        </ArchivingErrorWrapper>
    );
};

export default ArchivingError;
