import {driverListTabs} from 'pages/Drivers/constants/tabs';
import {truckListTabs} from 'pages/Trucks/constants/list';

import type {AdminSettingsActionsTypes} from './actionCreators';
import {SET_ADMIN_SETTINGS} from './actionTypes';

export type AdminSettingsState = {
    trucks: {
        activeListTab: keyof typeof truckListTabs;
    };
    drivers: {
        activeListTab: keyof typeof driverListTabs;
    };
    dispatchers: {
        showDeleted: boolean;
    };
    carriers: {
        showDeleted: boolean;
    };
    brokers: {
        showDeleted: boolean;
    };
    customers: {
        showDeleted: boolean;
    };
    facilities: {
        showDeleted: boolean;
    };
    owners: {
        showDeleted: boolean;
    };
};

const defaultState = {
    trucks: {
        activeListTab: truckListTabs.general,
    },
    drivers: {
        activeListTab: driverListTabs.general,
    },
    dispatchers: {
        showDeleted: false,
    },
    carriers: {
        showDeleted: false,
    },
    brokers: {
        showDeleted: false,
    },
    customers: {
        showDeleted: false,
    },
    facilities: {
        showDeleted: false,
    },
    owners: {
        showDeleted: false,
    },
};

const adminSettings = (
    state: AdminSettingsState = defaultState,
    action: AdminSettingsActionsTypes,
): AdminSettingsState => {
    switch (action.type) {
        case SET_ADMIN_SETTINGS:
            return {
                ...state,
                ...action.payload,
            };

        // no default
    }
    return state;
};

export default adminSettings;
