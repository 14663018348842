import {useSelector} from 'react-redux';

import * as selectors from 'pages/Trucks/redux/selectors/selectCompanies';

type UseSelectCompaniesState = {
    searchMatchCount: ReturnType<typeof selectors.getCompaniesSearchMatchCount>;
    searchParams: ReturnType<typeof selectors.getCompaniesSearchParams>;
    allAllianceCount: ReturnType<typeof selectors.getAllAllianceCount>;
    initialCompanies: ReturnType<typeof selectors.getInitialCompanies>;
    companies: ReturnType<typeof selectors.getCompanies>;
};

const useSelectCompaniesState = (): UseSelectCompaniesState => ({
    searchMatchCount: useSelector(selectors.getCompaniesSearchMatchCount),
    searchParams: useSelector(selectors.getCompaniesSearchParams),
    allAllianceCount: useSelector(selectors.getAllAllianceCount),
    initialCompanies: useSelector(selectors.getInitialCompanies),
    companies: useSelector(selectors.getCompanies),
});

export default useSelectCompaniesState;
