import React from 'react';

import Truck from 'core/entities/Truck/types';

import {TruckLink} from 'components/ui/Links';

const DriverTruckOnRoute: React.FC<{truck: Truck}> = (props) => {
    const {truck} = props;
    return (
        <div>
            <div>
                The driver can&apos;t be updated because{' '}
                <strong>
                    <TruckLink truck={truck} content={`truck # ${truck.number}`} className="second-link" />
                </strong>{' '}
                has loads In Progress or Planned statuses. Please, wait for the truck to complete the travel order.
            </div>
        </div>
    );
};

export default DriverTruckOnRoute;
