import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import sortBy from 'lodash/sortBy';

import {getCurrentSortBy, getCurrentSortFrom} from 'store/selectors';

import Carrier from 'core/entities/Carrier/types';

import {SelectCompaniesModalFormValues} from 'pages/Trucks/types/formTypes';

const ALLIANCE_COMPANIES_DTYPE = 'dispatchlandcarrier';

export const transformDataToRequestBody = (params: {state; formValues?: Partial<SelectCompaniesModalFormValues>}) => {
    const {state, formValues} = params;

    const currentSortFrom = getCurrentSortFrom(state, 'selectCompaniesModal');
    const currentSortBy = getCurrentSortBy(state, 'selectCompaniesModal');

    const searchParams = {
        sortBy: currentSortBy ? {[currentSortBy]: currentSortFrom} : null,
        company_name: formValues?.companyName || null,
        'per-page': 0,
    };

    return omitBy(searchParams, (value) => isNull(value) || isUndefined(value));
};

export const handleReducerByInitialCompaniesReceived = (params: {state; companies: Carrier[]}) => {
    const {state, companies} = params;

    const items = companies.filter((item) => item.dtype === ALLIANCE_COMPANIES_DTYPE);

    return {
        ...state,
        companies: items,
        initialCompanies: items,
        allAllianceCount: items.length,
    };
};

export const handleReducerByCompaniesBySortingReceived = (params: {state; companies: Carrier[]}) => {
    const {state, companies} = params;

    const items = companies.filter((item) => item.dtype === ALLIANCE_COMPANIES_DTYPE);

    return {
        ...state,
        companies: items,
    };
};

export const handleReducerByCompaniesSearchParamsReceived = (params: {
    state;
    filters: Partial<SelectCompaniesModalFormValues>;
}) => {
    const {state, filters} = params;

    const searchParams = {
        companyName: filters?.companyName || null,
    };

    return {
        ...state,
        searchParams: omitBy(searchParams, (value) => isNull(value) || isUndefined(value)),
    };
};

export const handleReducerByCompaniesByFiltersReceived = (params: {
    state;
    filters: Partial<SelectCompaniesModalFormValues>;
}) => {
    const {state, filters} = params;

    if (!filters?.companyName) {
        return {
            ...state,
            searchMatchCount: 0,
            companies: state.initialCompanies,
        };
    }

    let searchMatchCount = 0;

    const companies = sortBy(state.companies, (item) => {
        const name = item.company_name.toLowerCase();
        const isMatch = name.includes(filters?.companyName?.toLowerCase());

        if (isMatch) {
            searchMatchCount += 1;
        }

        return isMatch ? 0 : 1;
    });

    return {
        ...state,
        searchMatchCount,
        companies,
    };
};
