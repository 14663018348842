export const GENERAL = {
    section: 'general',
    updateTitle: 'General',
    createTitle: 'Step 1. General',
};

export const CONTACT = {
    section: 'contact',
    updateTitle: 'Contact',
    createTitle: 'Step 2. Contact',
};

export const FILES = {
    section: 'files',
    updateTitle: 'Files',
};

export const LIST_TABS = {
    DEACTIVATED: 'DEACTIVATED',
    ARCHIVED: 'ARCHIVED',
    GENERAL: 'GENERAL',
} as const;
