import React, {FC} from 'react';
import classNames from 'classnames';

export type ButtonType = {
    onSelect();
    name: string;
    label: string;
    isDisabled?: boolean;
    isHideButton?: boolean;
};

type OwnProps = ButtonType & {
    activeKey: string;
};

const Button: FC<OwnProps> = (props) => {
    const {onSelect, isDisabled, name, activeKey, label, isHideButton} = props;

    if (isHideButton) {
        return null;
    }

    const isButtonDisabled = name !== activeKey && isDisabled;
    const btnClassName = classNames('btn btn-default', {active: activeKey === name, disabled: isButtonDisabled});

    return (
        <button type="button" className={btnClassName} onClick={onSelect} disabled={isButtonDisabled}>
            {label}
        </button>
    );
};

export default Button;
